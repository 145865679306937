.add_product_remove_main_data {
  display: flex;
  justify-content: space-between;
}

.add_product_parent_div_inner {
  display: flex;
  align-items: center;
}

.frame_info_text_files_name_browse {
  display: flex;
  align-items: center;
}

span.delete_icon {
  margin-right: 8px;
}

.add_product_images_add_inner_div_main {
  display: flex;
}

img.frame_info_image_inner_open_inner {
  margin: auto;
}

.frame_information_left_images_open_browse_main {
  border-radius: 20px;
  border: 1px dashed #219ebc;
  width: 30.125rem;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.frame_info_image_inner_open_bottom_div_delete_icon {
  overflow: auto;
  height: 13rem;
}

.frame_information_left_images_open_browse_main {
  margin-right: 30px;
}

.frame_info_image_inner_open_bottom_div_delete_icon {
  width: 100%;
}

button.frame_info_images_remove_icon_inner_delete {
  display: flex;
  justify-content: center;
}

img.frame_info_image_inner_open_inner {
  margin-bottom: 22px;
}

button.frame_info_images_remove_icon_inner_delete span {
  margin: 0;
}

.frame_show_images_selected_file_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.frame_show_images_name_complete_done_div {
  display: flex;
  align-items: center;
}

img.frame_show_images_selected_file_inner_outer {
  width: 90px;
  height: 90px;
  border-radius: 5px;
  object-fit: contain;
}

.frame_show_images_name_complete_done_div p {
  margin-left: 24px;
}

.frame_info_image_inner_open_bottom_div_inner>div:not(:last-child) {
  margin-bottom: 20px;
}

.add_product_remove_main_data {
  margin-bottom: 30px;
}

.add_product_inner {
  margin: 20px 0;
}

.frame_info_text_files_name_browse_inner p:first-child {
  text-align: center;
  margin-bottom: 8px;
}

.frame_info_image_inner_open_bottom_div_delete_icon {
  overflow: auto;
}

.add_product_images_add_inner_div_main {
  margin-bottom: 20px;
}

.frame_info_text_files_name_browse {
  cursor: pointer;
}

.add_product_images_add_inner_div_both_same {
  display: flex;
}

.product_images {
  margin-top: 20px;
}

.frame_info_text_files_name_browse_inner {
  cursor: pointer;
}

.frame_info_images_outer_inner_three_images_show p {
  text-align: center;
}