.login_right_main_page {
  padding: 48px;
}

.form_input_flex_div_mx_bottom {
  margin-bottom: 16px;
}

.login_right_back_page_main_div {
  display: flex;
}

.login_text_main_title {
  margin-bottom: 8px;
}

.form_input_forget_text_main_pass_inner_link_tag {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #219ebc;
}

.login_text_main_title_top_div_inner {
  margin-bottom: 27px;
}

.auth_form_div {
  width: 31.625rem;
  margin: 0 auto;
}

.form_input_forget_text_main_pass_inner {
  text-align: end;
}

.form_get_update_text_data_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.form_input_list_email_pass_other_data {
  margin-bottom: 27px;
}

.form_continue_text_title_main_inner_ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.form_continue_text_title_guest_inner {
  margin-left: 4px;
}

.form_data_or_text_div_line_main {
  margin: 27px 0;
}

.form_google_apple_ios_btn_inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

span.form_google_icons_main {
  margin-right: 16px;
  font-size: 24px;
}

.form_google_apple_ios_btn_inner {
  border-radius: 14px;
  border: 1px solid #eaeef0;
  padding: 17px 0;
  width: 100%;
}

.form_google_apple_ios_btn_inner {
  margin-bottom: 20px;
}

.form_line_para_or_main_div_add {
  position: relative;
  text-align: center;
  color: #a7abac;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.form_line_para_or_main_div_add::after {
  position: absolute;
  content: '';
  border-radius: 61px;
  background: linear-gradient(
    90deg,
    rgb(70 206 239 / 20%) 0%,
    rgba(33, 158, 188, 0) 100%
  );
  width: 9.34rem;
  height: 2px;
  top: 50%;
  transform: translate(-50%);
  right: 0;
}

.form_line_para_or_main_div_add::before {
  position: absolute;
  content: '';
  border-radius: 61px;
  background: linear-gradient(
    90deg,
    rgb(70 206 239 / 20%) 0%,
    rgba(33, 158, 188, 0) 100%
  );
  width: 9.34rem;
  height: 2px;
  top: 50%;
  left: 5rem;
  rotate: -180deg;
}

.continue_as_guest {
  margin-left: 4px;
}

.login .login_right_inner_outer_info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
