.opt_send_number_inner {
  color: #2e3233;
}

input.inputClass {
  width: 63px !important;
  height: 63px !important;
  margin-right: 24px !important;
  background: #f4f8f9;
  border: 1px solid #eaeef0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
}

input.inputClass:not(:last-child) {
  margin-right: 0;
}

.timer_btn_value {
  position: relative;
}

.otp_input_text_filed_set {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp_input_text_filed_set {
  margin-bottom: 24px;
}

.timer_btn_value {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.otp_verify_code_num_inner_second {
  color: #868a8b;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.timer_btn_value > div > span {
  color: #023047;
  font-weight: 600;
  font-family: Poppins;
}

.otp_verification .form_sign_in_btn_main button.see_more_new_btn_inner {
  margin-top: 24px;
}

/* resend_otp
span.resend_otp {
  display: flex;
  width: fit-content; */
/* margin-left: auto; */
/* position: absolute; */
/* right: 0; */

.timer_btn_value > div > button {
  background: transparent !important;
}

.otp_verification .login_right_inner_outer_info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.otp_verification .auth_form_div {
  margin: 0;
}
