.color-chooser {
  width: 100%;
  display: flex;
}

.color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 10px;
  position: relative;
  z-index: 1;
  transition: transform 0.2s ease;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
    border: 2px solid #f1f1f1;
  }
}

.color-item-selected,
.color-item-deletable {
  border: 2px solid #f1f1f1;
  transform: scale(1.2);

  &:after {
    content: "✓";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    color: #fff;
  }
}

.color-item-deletable {
  &:after {
    content: "✖";
    display: none;
  }

  &:hover:after {
    display: block;
  }
}

.custom_color_input {
  display: flex;
}

.custom_color_input_inner {
  display: flex;
  align-items: center;
}

.custom_color_input {
  display: flex;
  justify-content: space-between;
}
.color-chooser {
  margin-top: 5px;
}
.custom_color_input_main_color_set_add {
  margin-top: 5px;
}
.input-group.product-form-field {
  display: flex;
}
.cutome_color_inner_main_outer_div {
  display: flex;
  align-items: center;
  /* margin-bottom: 8px; */
}
.cutome_color_inner_main_outer_div:last-child{
  margin-bottom: 0;
}