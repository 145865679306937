.checkout_main_section {
  background-color: #f6f6fc;
}

.checkout_main_section_shopping_cart_item {
  border-radius: 16px;
  border: 1px solid #eaeef0;
  background: #fff;
  border-bottom: 1px solid #eaeef0;
}
.checkout_main_section .cart-button-container {
  padding: 0;
}

.checkout_main_section_black_rectangle_add_num_input_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout_main_section_shopping_cart_item_main_images_icons_add {
  display: flex;
  align-items: center;
}

.checkout_main_section_black_rectangle_add_num_input {
  width: 100%;
}

.checkout_main_section_black_rectangle_add_num_input {
  margin-left: 16px;
}
.checkout_main_section_black_rectangle_add_num_input_inner:first-child {
  margin-bottom: 8px;
}

.checkout_main_section_shopping_cart_item_main_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout_main_section_shopping_cart_item_main_line_top_bottom {
  border-top: 1px solid #eaeef0;
  border-bottom: 1px solid #eaeef0;
}
.checkout_main_section_shopping_cart_item_main_head {
  padding: 20px;
}

.checkout_main_section_shopping_cart_item_main_line_top_bottom {
  padding: 20px;
}

.checkout_main_section_sub_total_main {
  padding: 20px;
}

.checkout_main_section_sub_total_main_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout_main_section_sub_total_main_inner:not(:last-child) {
  margin-bottom: 20px;
}
.checkout_main_section_sub_total_main_inner p {
  display: flex;
  align-items: center;
}
.checkout_main_section_radio_button_text_inner {
  display: flex;
  align-items: center;
}

.checkout_main_section .auth_page_form_main {
  margin: 0;
  width: 35.3125rem;
}
.checkout_main_section_main_secure_login_list_data_add_inner p {
  display: flex;
  align-items: center;
}

.checkout_main_section_radio_button_text:first-child {
  margin-bottom: 12px;
}
.checkout_main_section .form_sign_in_btn_main {
  margin-top: 40px;
}
.checkout_main_section_main_secure_login_list_data_add p {
  margin-bottom: 12px;
}
.checkout_main_section_main_secure_login_list_data_add_inner span {
  margin-left: 0;
}
.checkout_main_section_main_secure_login_list_data_add {
  margin: auto;
  width: fit-content;
}
.checkout_main_div {
  padding: 32px;
}

/* .login_booking_stepper_continue {
  padding: 0 32px 32px;
} */

/* p.custom-active-text {
  color: #fff;
} */

label.checkout_main_stepper_label_main {
  display: flex;
  align-items: center;
}
label.checkout_main_stepper_label_main p {
  margin-left: 12px;
}
label.checkout_main_stepper_label_main_inner_outer_div {
  margin-left: 16px;
}
.checkout_main_stepper_label_main_inner_outer_div_main {
  margin-bottom: 12px;
}

.checkout_main_section .MuiStepLabel-iconContainer {
  display: none;
}
span.step_number {
  border-radius: 8px;
  background: var(--shade-100, #f4f8f9);
  height: 38px;
  width: 38px;
  color: var(--primary-blue, #219ebc);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.checkout_main_div.stepper_bg_inner_details_add_div_inner_main {
  padding: 0;
}
.login_for_booking_order_stepper_main_change_button {
  display: flex;
  justify-content: space-between;
}

.login_for_booking_order_stepper_main_change_button {
  background-color: #fff;
  padding: 16px;
  border-radius: 14px;
}

.login_for_booking_order_stepper_main_change_button.custom-active {
  background: #219ebc;
  border-radius: 16px 16px 0 0;
}

.login_for_booking_order_stepper_main_change_button.custom-active
  .custom-active-text {
  color: #fff;
}

.checkout_main_section .MuiStepLabel-vertical {
  padding: 0 !important;
}

.checkout_main_section_main_from_data_add.stepper_delivery_address_main,
.stepper_bg_inner_details_add_div {
  background-color: #fff;
  border-radius: 0 0 14px 14px;
}

.add_new_address_main_div {
  background-color: #fff;
  margin-top: 8px;
  border-radius: 14px;
}

.step_login_inner_outer_class_add_user_add_text_titile p {
  padding-top: 8px;
  display: flex;
  align-items: center;
}

.step_login_inner_outer_class_add_true svg {
  margin-left: 8px;
}

.login_sign_in_guest_user_mobile {
  color: #868a8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.login_sign_in_guest_user_dot {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #219ebc;
  display: flex;
  margin: 0 12px;
}
