@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
}

.app {
  display: flex;
  position: relative;
}

.technical_information_text_filed {
  margin-bottom: 20px;
}
.technical_information_flex_inner {
  width: 100%;
}

.category_modal_add_form_main_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category_modal_add_form_main_div {
  margin-bottom: 10px;
}

.app {
  height: 100vh;
  overflow: hidden;
}

main.content {
  overflow-y: auto;
}