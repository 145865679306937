.auth_frames {
  background: url(../../../assets/images/auth_slider_bg.png) no-repeat;
  background-size: cover;
}

.auth_slider_para_inner_text_stylish {
  max-width: 360px;
  text-align: center;
}

.auth_slider_para_inner_text_stylish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img.auth_slider_main_images_inner {
  margin: auto;
}

.auth_slider_para_inner_text_stylish {
  margin-top: 50px;
}


section.auth_slider_main {
  height: 100dvh;
  overflow: hidden;
}

.grid.grid-cols-2 {
  height: inherit;
}

.auth_frames {
  height: 100%;
}

.auth_slider_main_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}

.auth_frames_inner_logo_main_div {
  padding: 48px 48px 0;
}

.auth_frames_inner_logo_main_div {
  position: absolute;
  top: 48px;
  background: url(../../../assets/images/auth_slider_logo.png) no-repeat;
  background-size: contain;
  width: 13.85rem;
  height: 39px;
  left: 48px;
  cursor: pointer;
}

.auth_frames {
  position: relative;
}

.auth_slider_main_images_top {
  gap: 70px;
}