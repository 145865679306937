.forgot .login_right_inner_outer_info {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.forgot .form_input_list_email_pass_other_data {
  margin-bottom: 24px;
}
