.order_details_write_a_review_main_div_drop_down {
    margin-top: 20px;
  }
  
  .order_details_rating_review {
    margin: 12px 0 10px;
  }
  
  .order_details_review_text_area {
    margin-bottom: 20px;
  }
  
  form.order_details_review_form {
    width: 30.75rem;
  }
  
  .order_details_main_details > div {
    margin-bottom: 24px;
  }
  
  .order_details_payment_price_details {
    margin-bottom: 20px;
  }
  
  .order_details_price_breakup_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .complete_stepper_span_border {
    border-radius: 100%;
    border: 1px solid #219ebc;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
    z-index: 9;
  }
  
  .order_details_track_order_stepper_main_para_flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .my_order_main_details_status_order_details {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .order_details_track_order_stepper_inner {
    margin-top: 22px;
  }
  
  .order_details_track_order_stepper_inner > p {
    margin-bottom: 4px;
  }
  
  .order_details_main_details_status_order_details_data p:last-child {
    margin-top: 4px;
    margin-bottom: 8px;
  }
  
  .order_details_track_order_stepper_main {
    padding: 36px 0 16px;
  }
  
  .order_details_track_order_address_details {
    display: flex;
    /* align-items: center; */
  }
  
  .order_details_track_order_address_details_inner {
    width: 50%;
  }
  
  .order_details_track_order_address_details_inner_head {
    margin-bottom: 12px;
  }
  
  .order_details_track_order_address_details_inner > p {
    margin-bottom: 4px;
  }
  
  .order_details_track_order_address_details_para {
    max-width: 152px;
  }
  